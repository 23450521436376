import { faCalendar, faCreditCard, faLocationDot, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BulletPoint from '../../components/about/bullet';
import Layout from '../../components/layout';
import PageSection from '../../components/page-section';
import Seo from '../../components/seo';
const VistulaDayTicketsAboutPage = () => {
    const goToEventsSchedule = () => { navigate('/otwarcie-sezonu-2023/atrakcje') };
    return (
        <Layout>
            <Seo title={"Otwarcie sezonu nad Wisłą 2023 - Bilety"} />
            <PageSection>
                <h1><strong>Bilety online</strong><br />Otwarcie sezonu nad Wisłą 2023</h1>
                <div className="py-3"></div>
                <h3>Rezerwacja bezpłatnych biletów na atrakcje obchodów</h3>
                <div className="py-3"></div>

                <p>W atrakcjach biletowanych część całkowitej puli biletów udostępniliśmy do rezerwacji online na tej stronie. Pozostała ilość będzie zależna od frekwencji i dostępna na miejscu.</p>
                <div className="pt-3">
                    <button onClick={goToEventsSchedule} type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj</button>
                    <span className="ps-3"></span>
                    <a id="read-more-tickets" href="#more-about-tickets-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                </div>
            </PageSection>
            <PageSection className='bg-white text-black' id="more-about-tickets-section" data-bs-spy="scroll" data-bs-target="#read-more-tickets">
                <div className="container-fluid px-4 px-md-0 py-2">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-6">
                                    <BulletPoint icon={faLocationDot} title={"Odbiór biletów"} >
                                        <span>Zarezerwowany bilet należy okazać w formie potwierdzenia mailowego z numerem rezerwacji.<strong> Na miejsce należy zjawić się najpóźniej 10 minut przed terminem rozpoczęcia atrakcji</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCalendar} title={"Otwarcie rezerwacji"} >
                                        <span>Otwarcie zapisów nastąpi w 19 czerwca o godzinie 12:00. Dodatkowe pule biletów zostaną ogłoszone na <a target="_blank" href="https://www.facebook.com/swietowisly/">profilu społecznościowym wydarzenia</a>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faPeopleLine} title={"Ograniczenia"} >
                                        <span>Jedna osoba może złożyć rezerwację na grupę <strong>maks. 4 osób na 1 atrakcję</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCreditCard} title={"Kaucja zwrotna"} >
                                        <span>Na każdy z rejsów pobieramy kaucję zwrotną w wysokości <br /><strong>20zł /os</strong>.</span>
                                    </BulletPoint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
        </Layout>
    );
}

export default VistulaDayTicketsAboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;